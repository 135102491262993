import {Os} from "@/store/modules/ApiModule";

const oses: Array<Os> = [
    {
        id: "GOOGLE PLAY",
        name: "GOOGLE PLAY"
    },
    {
        id: "APP STORE",
        name: "APP STORE"
    }
];

export default oses;
