
import {computed, defineComponent, onMounted, reactive, ref, watch} from "vue";
import { hideModal } from "@/core/helpers/dom";
import oses from "@/core/data/oses";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {useStore} from "vuex";
import {AsoProject, Client, Integration} from "@/store/modules/ApiModule";
import {Actions} from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "add-aso-project-modal",
  components: {},
  props: {
    asoproject: {
      type: Object as () => AsoProject,
      required: true
    }
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const addAsoProjectModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const formData = reactive<AsoProject>({
      id: 0, client_id: null, name: "", os: "", app: "", countries: "", console_id: null,
      appbot_id: null, apptweak_id: null,
      aso_spreadsheet: "", console_spreadsheet: "",
      client: {} as Client,
      console: {} as Integration,
      appbot: {} as Integration,
      apptweak: {} as Integration
    });
    const store = useStore();

    watch(props.asoproject, (newValue, oldValue) => {
      Object.assign(formData, newValue);
      if(formRef.value){
        formRef.value.clearValidate();
      }
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "ASO Project name is required",
          trigger: "change",
        },
      ],
      countries: [
        {
          required: true,
          message: "Specify at least one country. Use WW for global data",
          trigger: "change",
        },
      ],
    });

    let consoleIntegrations = computed(() => {
      return store.state.api.integrations.filter(integration => {
        if(formData.os == "GOOGLE PLAY"){
          return integration.thirdparty.id === 6
        }
        else if(formData.os == "APP STORE"){
          return integration.thirdparty.id === 7
        }
        else {
          return false
        }
      });
    });

    let apptweakIntegrations = computed(() => {
      return store.state.api.integrations.filter(integration => {
        return integration.thirdparty.id === 8
      });
    });

    /*let consoleAccounts = computed(() => {
      let integrations = store.state.api.integrations.filter(integration => integration.id == formData.console_id);
      if(integrations.length == 0){
        return [{
          name: "Select a console account ",
          id: 0
        }];
      }
      else {
        return integrations[0].data.accounts;
      }
    });

    let consoleAccountApps = computed(() => {
      let integrations = store.state.api.integrations.filter(integration => integration.id == formData.console_id);
      if(integrations.length == 0){
        return [{
          name: "Select a console integration",
          id: 0
        }];
      }
      else {
        let accounts = integrations[0].data.accounts.filter(account => account.id == formData.console_account);
        if(accounts.length == 0){
          return [{
            name: "Select a console account",
            id: 0
          }];
        }
        else {
          return accounts[0].apps;
        }
      }
    });*/

    onMounted(() => {
      store.dispatch(Actions.LOAD_CLIENTS);
      store.dispatch(Actions.LOAD_INTEGRATIONS);
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          //loading.value = true;
          ApiService.setHeader();
          return ApiService.post("asoprojects", formData)
            .then(() => {
              store.dispatch(Actions.LOAD_ASOPROJECTS);
              Swal.fire({
                text: "ASO Project has been saved!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                if(formRef.value){
                  formRef.value.resetFields();
                }
                hideModal(addAsoProjectModalRef.value);
              });
            })
            .catch((error) => {
              Swal.fire({
                text: error.response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      formData,
      rules,
      store,
      oses,
      consoleIntegrations,
      apptweakIntegrations,
      //consoleAccounts,
      submit,
      formRef,
      loading,
      addAsoProjectModalRef,
    };
  },
});
