
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {AsoProject} from "@/store/modules/ApiModule";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "update-aso-report-modal",
  components: {},
  props: {
    asoproject: {
      type: Object as () => AsoProject,
      required: true
    }
  },
  setup(props) {

    const formRef = ref<null | HTMLFormElement>(null);
    const updateAsoReportModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    let formData = reactive({
      dates: [],
      countries: "",
      options: []
    });
    const store = useStore();

    const rules = ref({});

    let isAndroid = computed(() => {
      return props.asoproject.os == "GOOGLE PLAY"
    });

    watch(props.asoproject, (newValue, oldValue) => {
      formData.countries = newValue.countries
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          let payload = {
            id: props.asoproject.id,
            from: formData.dates[0],
            to: formData.dates[1],
            countries: formData.countries,
            options: formData.options
          }

          loading.value = true;
          ApiService.setHeader();
          return ApiService.post("asoprojects/report", payload)
            .then((response) => {
              loading.value = false;
              Swal.fire({
                text: response.data.numUpdatedRows + " rows have been updated",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                if(formRef.value){
                  formRef.value.resetFields();
                }
                hideModal(updateAsoReportModalRef.value);
              });
            })
            .catch((error) => {
              loading.value = false;
              Swal.fire({
                text: error.response.data.message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Close",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      isAndroid,
      formData,
      rules,
      submit,
      formRef,
      loading,
      updateAsoReportModalRef,
    };
  },
});
